// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-new-homes-classic-centre-hall-jsx": () => import("./../../../src/pages/portfolio/new-homes/classic-centre-hall.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-classic-centre-hall-jsx" */),
  "component---src-pages-portfolio-new-homes-credit-river-manor-jsx": () => import("./../../../src/pages/portfolio/new-homes/credit-river-manor.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-credit-river-manor-jsx" */),
  "component---src-pages-portfolio-new-homes-hoggs-hollow-french-country-jsx": () => import("./../../../src/pages/portfolio/new-homes/hoggs-hollow-french-country.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-hoggs-hollow-french-country-jsx" */),
  "component---src-pages-portfolio-new-homes-hoggs-hollow-french-jsx": () => import("./../../../src/pages/portfolio/new-homes/hoggs-hollow-french.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-hoggs-hollow-french-jsx" */),
  "component---src-pages-portfolio-new-homes-hoggs-hollow-traditional-jsx": () => import("./../../../src/pages/portfolio/new-homes/hoggs-hollow-traditional.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-hoggs-hollow-traditional-jsx" */),
  "component---src-pages-portfolio-new-homes-jsx": () => import("./../../../src/pages/portfolio/new-homes.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-jsx" */),
  "component---src-pages-portfolio-new-homes-kingsway-georgian-jsx": () => import("./../../../src/pages/portfolio/new-homes/kingsway-georgian.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-kingsway-georgian-jsx" */),
  "component---src-pages-portfolio-new-homes-kingsway-transitional-jsx": () => import("./../../../src/pages/portfolio/new-homes/kingsway-transitional.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-kingsway-transitional-jsx" */),
  "component---src-pages-portfolio-new-homes-oakville-executive-home-jsx": () => import("./../../../src/pages/portfolio/new-homes/oakville-executive-home.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-oakville-executive-home-jsx" */),
  "component---src-pages-portfolio-new-homes-traditional-kingsway-park-jsx": () => import("./../../../src/pages/portfolio/new-homes/traditional-kingsway-park.jsx" /* webpackChunkName: "component---src-pages-portfolio-new-homes-traditional-kingsway-park-jsx" */),
  "component---src-pages-portfolio-renovations-additions-etobicoke-arts-and-crafts-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/etobicoke-arts-and-crafts.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-etobicoke-arts-and-crafts-jsx" */),
  "component---src-pages-portfolio-renovations-additions-jsx": () => import("./../../../src/pages/portfolio/renovations-additions.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-jsx" */),
  "component---src-pages-portfolio-renovations-additions-lorne-park-interior-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/lorne-park-interior.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-lorne-park-interior-jsx" */),
  "component---src-pages-portfolio-renovations-additions-lytton-park-manor-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/lytton-park-manor.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-lytton-park-manor-jsx" */),
  "component---src-pages-portfolio-renovations-additions-princess-margaret-modern-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/princess-margaret-modern.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-princess-margaret-modern-jsx" */),
  "component---src-pages-portfolio-renovations-additions-rosedale-edwardian-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/rosedale-edwardian.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-rosedale-edwardian-jsx" */),
  "component---src-pages-portfolio-renovations-additions-royal-york-facelift-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/royal-york-facelift.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-royal-york-facelift-jsx" */),
  "component---src-pages-portfolio-renovations-additions-upper-canada-farmhouse-jsx": () => import("./../../../src/pages/portfolio/renovations-additions/upper-canada-farmhouse.jsx" /* webpackChunkName: "component---src-pages-portfolio-renovations-additions-upper-canada-farmhouse-jsx" */),
  "component---src-pages-portfolio-upcoming-jsx": () => import("./../../../src/pages/portfolio/upcoming.jsx" /* webpackChunkName: "component---src-pages-portfolio-upcoming-jsx" */),
  "component---src-pages-reviews-jsx": () => import("./../../../src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */)
}

